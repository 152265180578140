import React from 'react';
import { Link } from 'gatsby';

import links from '../data/links.json';

import Layout from '../components/Common/Layout/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Common/Hero/Hero';

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo
      title="Privacy Policy"
      description="View the privacy policy for Luxor Management's website."
    />
    <Hero>
      <h1 className="h-page-hero-title my-4 md:my-0">Privacy Policy</h1>
    </Hero>
    <div className="l-container l-container--xs l-container--no-mobile-gutter">
      <article className="c-legal-box">
        <p>
          This page informs you of our policies regarding the collection, use
          and disclosure of Personal Information we receive from users of the
          Site.
        </p>
        <p className="mt-6">
          We use your Personal Information only for improving the Site. By using
          the Site, you agree to the collection and use of information in
          accordance with this policy.
        </p>
        <h2 className="text-lg font-bold mt-10">
          Information Collection & Use
        </h2>
        <p className="mt-3">
          While using our Site, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to your name and email address ("Personal Information").
        </p>
        <h2 className="text-lg font-bold mt-10">Log Data</h2>
        <p className="mt-3">
          Like many site operators, we collect information that your browser
          sends whenever you visit our Site ("Log Data").
        </p>
        <p className="mt-6">
          This Log Data may include information such as your computer's Internet
          Protocol ("IP") address, browser type, browser version, the pages of
          our Site that you visit, the time and date of your visit, the time
          spent on those pages and other statistics.
        </p>
        <p className="mt-6">
          In addition, we may use third party services such as Google Analytics
          that collect, monitor and analyze the above.
        </p>
        <h2 className="text-lg font-bold mt-10">Communications</h2>
        <p className="mt-3">
          We may use your Personal Information to contact you with newsletters,
          marketing or promotional materials and other information that would be
          applicable to our business and/or services.
        </p>
        <h2 className="text-lg font-bold mt-10">Cookies</h2>
        <p className="mt-3">
          Cookies are files with small amount of data, which may include an
          anonymous unique identifier.
        </p>
        <p className="mt-6">
          Cookies are sent to your browser from a web site and stored on your
          computer's hard drive.
        </p>
        <p className="mt-6">
          Like many sites, we use "cookies" to collect information. You can
          instruct your browser to refuse all cookies or to indicate when a
          cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our Site.
        </p>
        <h2 className="text-lg font-bold mt-10">Security</h2>
        <p className="mt-3">
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee absolute security.
        </p>
        <h2 className="text-lg font-bold mt-10">
          Changes to this Privacy Policy
        </h2>
        <p className="mt-3">
          This Privacy Policy is effective as of January 26, 2017 and will
          remain in effect except with respect to any changes in its provisions
          in the future, which will be in effect immediately after being posted
          on this page.
        </p>
        <p className="mt-6">
          We reserve the right to update or change our Privacy Policy at any
          time and you should check this Privacy Policy periodically. Your
          continued use of the Service after we post any modifications to the
          Privacy Policy on this page, will constitute your acknowledgement of
          the modifications and your consent to abide and be bound by the
          modified Privacy Policy.
        </p>
        <h2 className="text-lg font-bold mt-10">Contact Us</h2>
        <p className="mt-3">
          If you have any questions about this Privacy Policy, please{' '}
          <Link
            role="link"
            className="text-brand-primary hover:underline"
            to={links.contact}
          >
            contact us
          </Link>
          .
        </p>
      </article>
    </div>
  </Layout>
);

export default PrivacyPolicyPage;
